import React from "react";
import {
   ChakraProvider,
   CSSReset,
   extendTheme,
   Box,
   Container,
   Text,
   Flex,
   Image,
   Badge,
} from "@chakra-ui/react";
import Ghan from "../images/Ghanshyam.jpg"
const theme = extendTheme({
   fonts: {
      body: "Arial, sans-serif",
   },
});

const teamMembers = [
   {
      imageUrl: "https://example.com/member1.jpg",
      name: "John Doe",
      education: "Bachelor's in Civil Engineering",
      description:
         "John is an experienced civil engineer with a passion for sustainable construction projects. He has a strong background in structural engineering and project management.",
      position: "Senior Civil Engineer",
   },
   {
      imageUrl: Ghan,
      name: "Ghanshyam Mandloi",
      education: "Bachelor's in Civil Engineering",
      description:
         "John is an experienced civil engineer with a passion for sustainable construction projects. He has a strong background in structural engineering and project management.",
      position: "Senior Civil Engineer",
   },
   {
      imageUrl: "https://example.com/member1.jpg",
      name: "John Doe",
      education: "Bachelor's in Civil Engineering",
      description:
         "John is an experienced civil engineer with a passion for sustainable construction projects. He has a strong background in structural engineering and project management.",
      position: "Senior Civil Engineer",
   },
   {
      imageUrl: "https://example.com/member1.jpg",
      name: "John Doe",
      education: "Bachelor's in Civil Engineering",
      description:
         "John is an experienced civil engineer with a passion for sustainable construction projects. He has a strong background in structural engineering and project management.",
      position: "Senior Civil Engineer",
   },
   {
      imageUrl: "https://example.com/member1.jpg",
      name: "John Doe",
      education: "Bachelor's in Civil Engineering",
      description:
         "John is an experienced civil engineer with a passion for sustainable construction projects. He has a strong background in structural engineering and project management.",
      position: "Senior Civil Engineer",
   },
   {
      imageUrl: "https://example.com/member1.jpg",
      name: "John Doe",
      education: "Bachelor's in Civil Engineering",
      description:
         "John is an experienced civil engineer with a passion for sustainable construction projects. He has a strong background in structural engineering and project management.",
      position: "Senior Civil Engineer",
   },
   // Add more team members here
];

function TeamMembers() {
   return (
      <ChakraProvider theme={theme}>
         <Badge p={"0 40px"} fontSize="lg" fontWeight="bold" lineHeight="short">
            Our Team
         </Badge>
         <CSSReset />
         <Container maxW="container.l" py={8}>
            <Flex flexWrap="wrap" justifyContent="space-between">
               {teamMembers.map((member, index) => (
                  <Box
                     key={index}
                     maxW="300px"
                     borderWidth="1px"
                     borderRadius="lg"
                     overflow="hidden"
                     boxShadow="md"
                     p={4}
                     mb={4}
                  >
                     <Image
                        src={member.imageUrl}
                        alt={member.name}
                        // boxSize="200px"
                        width={"100%"}
                        objectFit="cover"
                     />

                     <Box mt={4}>
                        <Text
                           fontSize="lg"
                           fontWeight="semibold"
                           lineHeight="short"
                        >
                           {member.name}
                        </Text>
                        <Text color="gray.600">{member.education}</Text>
                        <Text mt={2} color="gray.700">
                           {member.description}
                        </Text>
                     </Box>

                     <Flex
                        mt={4}
                        justifyContent="space-between"
                        alignItems="center"
                     >
                        <Text
                           fontSize="lg"
                           fontWeight="semibold"
                           color="teal.500"
                        >
                           {member.position}
                        </Text>
                     </Flex>
                  </Box>
               ))}
            </Flex>
         </Container>
      </ChakraProvider>
   );
}

export default TeamMembers;
