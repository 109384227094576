import React from "react";
import {
   Box,
   Text,
   Center,
   Link,
   Divider,
   Flex,
   Spacer,
} from "@chakra-ui/react";

function Footer() {
   return (
      <Box bg="gray.800" p={4} mt={10}>
         <Center>
            <Text color="white" fontSize="sm" textAlign="center">
               &copy; {new Date().getFullYear()} Your Construction & Surveying
               Associates. All rights reserved.
            </Text>
         </Center>
         <Divider mt={2} borderColor="gray.600" />
         <Flex mt={2} justifyContent="center">
            <Link color="white" fontSize="sm" mr={4} href="/privacy-policy">
               Privacy Policy
            </Link>
            <Link color="white" fontSize="sm" mr={4} href="/terms-of-service">
               Terms of Service
            </Link>
            <Link color="white" fontSize="sm" href="/contact">
               Contact Us
            </Link>
         </Flex>
         <Spacer />
         <Text color="white" fontSize="xs" textAlign="center" mt={2}>
            Your Construction & Surveying Associates
            <br />
            1234 Main Street, New York, NY 10001
            <br />
            Email: info@example.com
            <br />
            Phone: +1 (123) 456-7890
         </Text>
      </Box>
   );
}

export default Footer;
