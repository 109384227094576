import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Land from "./Land";
import AutoLevel from "./AutoLevel"
const AllRoutes = () => {
   return (
      <Routes>
         <Route path="/" element={<Home />} />
         <Route  path="/land" element = {<Land/>}/>
         <Route path = "/auto/level" element = {<AutoLevel/>}/>
      </Routes>
   );
};

export default AllRoutes;
