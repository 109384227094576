import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  List,
  ListItem,
  Link,
  IconButton,
  useMediaQuery,
  useColorMode,
  VStack, // Added VStack for vertical stacking
} from "@chakra-ui/react";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [active, setActive] = useState(false);
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)");
  const { colorMode, toggleColorMode } = useColorMode();

  const toggleMenu = () => {
    setActive(!active);
  };


  const menuItems = [
    {
      title: "Home",
      sectionId: "home",
      url:"/"
    },
    {
      title: "Services",
      sectionId: "services",
      url:"/"
      
    },
    {
      title: "Our Team",
      url:"/",
      sectionId: "team",
    },
    {
      title: "Contact",
      url:"/",
      sectionId: "contact",
    },
  ];

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      bg={colorMode === "light" ? "gray.100" : "gray.900"}
      // color={colorMode === "light" ? "black" : "white"}
      height="70px"
      padding="0 20px"
      boxShadow="0px 2px 5px rgba(0, 0, 0, 0.1)"
      position="sticky"
      top="0"
      zIndex="10"
    >
      <Text
        fontWeight="medium"
        cursor="pointer"
        fontSize="2.4rem"
        color={"red.800"}
        fontFamily="'Brush Script MT', cursive"
      >
        Bhagawati Associates
      </Text>

      {!isLargerThanMobile && (
        <IconButton
          icon={active ? <FaTimes /> : <FaBars />}
          variant="ghost"
          onClick={toggleMenu}
          fontSize="xl"
          color={colorMode === "light" ? "black" : "white"}
          aria-label="Toggle Menu"
        />
      )}

      {(isLargerThanMobile || active) && (
        <Box
          display="flex"
          alignItems="center"
          ml={isLargerThanMobile ? "2rem" : "0"}
        >
          {isLargerThanMobile ? (
            // Horizontal menu for larger screens
            <List display="flex">
              {menuItems.map((item, index) => (
                <ListItem key={index}>
                  <Link
                    onClick={() => window.location.href = `${item.url}`}
                    padding="1rem 1.5rem"
                    textDecoration="none"
                    _hover={{
                      backgroundColor: "gray.200",
                      borderRadius: "4px",
                    }}
                    cursor="pointer"
                  >
                    {item.title}
                  </Link>
                </ListItem>
              ))}
            </List>
          ) : ( 
            // Vertical menu for smaller screens
            <VStack spacing="1rem" paddingTop={"100px"}>
              {menuItems.map((item, index) => (
                <Link
                  key={index}
                  onClick={() => window.location.href = `${item.url}`}
                  textDecoration="none"
                  _hover={{
                    backgroundColor: "gray.200",
                    borderRadius: "4px",
                  }}
                  cursor="pointer"
                >
                  {item.title}
                </Link>
              ))}
            </VStack>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default Navbar;
