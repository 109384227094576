import React from "react";
import {
   ChakraProvider,
   CSSReset,
   extendTheme,
   Box,
   Container,
   Text,
   Flex,
   Image,
   Badge,
   Heading,
   Button,
} from "@chakra-ui/react";

const theme = extendTheme({
   fonts: {
      body: "Arial, sans-serif",
   },
});

const surveys = [
   {
      imageUrl:
         "https://www.baselineequipment.com/shop/wpimages/1538082855-land-surveying-types.jpg",
      title: "Land Survey",
      description:
         "Surveying or land surveying is the technique, profession, art, and science of determining the terrestrial two-dimensional or three-dimensional positions of points and the distances and angles between them. A land surveying professional is called a land surveyor.",
      rate: "$150 per survey",
      type: "civil",
      url : "/land"
   },
   {
      imageUrl:
         "https://1streporting.com/wp-content/uploads/2022/04/construction-site-insepction_edited.jpg",
      title: "Construction Site Inspection",
      description:
         "Construction site inspections are critical to ensuring that quality and safety procedures are followed correctly from the start. Construction projects involve coordinating multiple project team members, materials, and equipment, which may expose contractors to many potential risks and hazards.",
      rate: "$200 per survey",
      url : "/inspection",
      type: "civil",
   },
   {
      imageUrl:
         "https://www.civilclick.com/wp-content/uploads/2020/05/auto-level.jpg",
      title: "Auto Level",
      description:
         "A dumpy level, automatic level, leveling instrument is an optical instrument used to establish or verify points in the same horizontal plane. It is used in surveying and building with a vertical staff to measure height differences and to transfer, measure and set heights.",
      rate: "$250 per survey",
      type: "civil",
      url : "/auto/level"

   },
   {
      imageUrl: "https://saarit.in/img/bg-img/stationab.jpg",
      title: "Total Station Survey",
      description:
         "Total station is a surveying equipment combination of Electromagnetic Distance Measuring Instrument and electronic theodolite. The instrument can be used to measure horizontal and vertical angles as well as sloping distance of object to the instrument.",
      rate: "$100 per survey",
      type: "customer",
      url : "/total/station"

   },
   {
      imageUrl:
         "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTflqXuV32zUvlVWN8tlcuwh2RsRrXoWrd_Q_4zzI16C5M0qmVVwoQ8nNGKMAsOYUdn91A&usqp=CAU",
      title: "DGPS Survey",
      description:
         "DGPS Survey achieves higher positional accuracy by making use of the differential capabilities of two GPS antennas linked by a radio signal. We use latest and efficient differential GPS equipment or DGPS instrument for DGPS survey procedure.",
      rate: "$ 250 per survey",
      type: "employee",
      url : "/dgps"

   },
   {
      imageUrl:
         "https://media.istockphoto.com/id/157781665/photo/surveyor-equipment-theodolite-at-construction-site.jpg?s=612x612&w=0&k=20&c=rlEVNz0dsiyr2j2ymBsoe558zV9UkJXDKspnaWY1U6g=",
      title: "Cadastral survey",
      description:
         "Cadastral surveying is the sub-field of cadastre and surveying that specialises in the establishment and re-establishment of real property boundaries.",
      rate: "$100 per survey",
      type: "customer",
      url : "/cadastral"

   },
   // Add more survey objects as needed
];

function SurveysTypes() {
   return (
      <ChakraProvider theme={theme}>
         <CSSReset />
         <Heading as="h2" size="lg" textAlign={"center"} m={4}>
            Our Surveys
         </Heading>

         <Container maxW="container.l" py={8}>
            <Flex flexWrap="wrap" justifyContent="space-between">
               {surveys.map((survey, index) => (
                  <Box
                     key={index}
                     maxW="300px"
                     borderWidth="1px"
                     borderRadius="lg"
                     overflow="hidden"
                     boxShadow="md"
                     p={4}
                     mb={4}
                  >
                     <Image
                        src={survey.imageUrl}
                        alt={survey.title}
                        width={"100%"}
                        objectFit="cover"
                     />

                     <Box mt={4}>
                        <Text
                           fontSize="lg"
                           fontWeight="semibold"
                           lineHeight="short"
                        >
                           {survey.title}
                        </Text>
                        <Text color="gray.600">{survey.description}</Text>
                     </Box>

                     <Flex
                        mt={4}
                        justifyContent="space-between"
                        alignItems="center"
                     >
                        {/* <Text
                           fontSize="lg"
                           fontWeight="semibold"
                           color="teal.500"
                        >
                           {survey.rate}
                        </Text> */}
                        <Button
                           colorScheme="teal" // Choose your desired color scheme
                           size="md" // Choose your desired size (sm, md, lg)
                           variant="solid" // Choose your desired variant (outline, solid, ghost)
                           borderRadius="full" // Makes the button round
                           _hover={{ bg: "teal.500" }} // Hover color
                           _active={{ bg: "teal.700" }} // Active (clicked) color
                           onClick={()=>{
                            // alert(`${survey.url}`)
                            window.location.href = `${survey.url}`
                           }}
                        >
                           See More
                        </Button>
                        {/* <Badge
                  colorScheme={survey.type === "customer" ? "blue" : "green"}
                >
                  {survey.type === "customer"
                    ? "Customer Survey"
                    : "Employee Survey"}
                </Badge> */}
                     </Flex>
                  </Box>
               ))}
            </Flex>
         </Container>
      </ChakraProvider>
   );
}

export default SurveysTypes;
