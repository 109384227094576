// // src/App.js
// import React from 'react';
// import NavBar from './components/Navbar';
// import Footer from './components/Footer';
// import ConstructionAndFacility from './components/ConstructionAndFacility';
// import ServicesSection from './components/Services';
// import Contact from './components/Contact';
// // import Carousel from './components/carousel/Carousel';
// import ResponsiveCarousel from './components/ResposiveCar';
// import FixedTabs from './components/Tabs';
// import SurveysTypes from './components/surveys/typesSurveys';
// import TeamMembers from './components/Team/TeamMembers';
// import InspiringSection from './components/Inspiring';

// function App() {

//   return (
//     <ChakraProvider>
//       <NavBar />
//       <img src="https://www.takeoffpros.com/wp-content/uploads/2020/02/02-construction-surveying.png.webp" alt="" width={"100%"} />

//         {/* <ConstructionAndFacility/> */}
//         {/* <ServicesSection/> */}
//         {/* <Carousel/> */}
//         {/* <ResponsiveCarousel/> */}
//        {/* <FixedTabs/> */}
//        <SurveysTypes/>
//        <img src="https://www.hbpateldiploma.org/slider/data1/images/cd2.png" alt="" />
//        <TeamMembers/>
//        <InspiringSection imageUrl={"https://www.onlineschoolsreport.com/wp-content/uploads/2020/10/OSR-FAQ-CivilEngineering-WhatIsSurveying-Header.jpg"} sentence={"Construction is a matter of optimism. It is facing the future with confidence."} />

//        <Contact/>

//       <Footer />
//     </ChakraProvider>
//   );
// }

// export default App;

import React from "react";

import AllRoutes from "./Pages/AllRoutes";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
// import LandSurveyComponent from "./Pages/asj";

function App() {
   return (
      <>
         <Navbar />
         <AllRoutes />
         <Footer />
      </>
   );
}

export default App;
