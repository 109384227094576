import React from "react";
import { Box, Heading, Text, Image } from "@chakra-ui/react"; // Import the necessary Chakra UI components
import InspiringSection from "../components/Inspiring";
import ContactPage from "../components/Contact";

const AutoLevel = () => {
   return (
      <Box
         maxW="full"
         mx="auto"
         p={4}
         borderWidth={1}
         borderRadius="lg"
         boxShadow="lg"
      >
         {/* <img src="https://scontent.fidr4-1.fna.fbcdn.net/v/t39.30808-6/302327291_4667506403352742_7046333737917406239_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=1b51e3&_nc_ohc=XXGV5F9DfYYAX8tAetR&_nc_ht=scontent.fidr4-1.fna&oh=00_AfDsrXFIxK0NFTHe_0_4_fiyg5p7svqnI623lJtOJUIEIA&oe=650CBB8E" alt="" /> */}
         <InspiringSection
            imageUrl={
               "https://scontent.fidr4-1.fna.fbcdn.net/v/t39.30808-6/302327291_4667506403352742_7046333737917406239_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=1b51e3&_nc_ohc=XXGV5F9DfYYAX8tAetR&_nc_ht=scontent.fidr4-1.fna&oh=00_AfDsrXFIxK0NFTHe_0_4_fiyg5p7svqnI623lJtOJUIEIA&oe=650CBB8E"
            }
         />
         <Heading as="h3" fontSize="lg" mb={4}>
            Introduction:
         </Heading>
         <Text mb={4}>
            An automatic level is an optical instrument used to establish or
            verify points in the same horizontal plane in a process known as
            leveling and is used in conjunction with a leveling staff to
            establish the relative heights levels of objects or marks. It is
            widely used in surveying and construction to measure height
            differences and to transfer, measure, and set heights of known
            objects or marks.
         </Text>
         <Text mb={4}>
            It is also known as a Surveyors level, Builders level, Dumpy level,
            or the historic "Y" level. It operates on the principle of
            establishing a visual level relationship between two or more points,
            for which an inbuilt telescope and a highly accurate bubble level
            are used to achieve the necessary accuracy.
         </Text>
         <Text mb={4}>
            It is usually used by contractors, builders, land surveying
            professionals, or the engineer who demands accurate leveling every
            time. Auto Levels set up fast, are easy to use, and save time and
            money on every job.
         </Text>
         <Image
            src="https://surveyforbeginners.files.wordpress.com/2018/12/autolevel-e1545063039944.jpg"
            alt="An Automatic Level"
            mb={4}
            boxSize="320px"
         />

         <Heading as="h3" fontSize="lg" mb={4}>
            Parts:
         </Heading>
         <Text mb={4}>
            An auto level consists of a telescope with various screws to adjust
            the line of sight. A tripod is also used to mount and adjust the
            auto level.
         </Text>
         <img
            src="https://surveyforbeginners.files.wordpress.com/2018/12/Capture-1.png"
            alt=""
         />
         <Text mb={4}>
            <strong>Telescope:</strong>
            {"  "} It holds the lenses that magnify objects in the sight.
         </Text>
         <Text mb={4}>
            {" "}
            <strong>Objective Lens :</strong>
            {"  "}It catches the object being sighted and magnifies the object.
         </Text>
         <Text mb={4}>
            {" "}
            <strong>Eyepiece :</strong>
            {"  "}It is located at the viewing end of the telescope, it can be
            turned to bring the crosshairs into focus.{" "}
         </Text>
         <Text mb={4}>
            {" "}
            <strong>Focusing Knob:</strong>
            {"  "}It can be turned to make objects appear crisp and clear.
         </Text>
         <Text mb={4}>
            {" "}
            <strong>Leveling Screws:</strong>
            {"  "} It allows adjustments to be made to ensure the instrument is
            level.
         </Text>
         <Text mb={4}>
            {" "}
            <strong>Base Plate:</strong>
            {"  "}It is the area to which the automatic level attaches on the
            tripod.
         </Text>
         <Text mb={4}>
            {" "}
            <strong>Horizontal Tangent Screw:</strong>
            {"  "}It can be adjusted to make the instrument move left or right
            on the horizontal plate. It is also known as ‘slow motion knobs’.
         </Text>
         <Text mb={4}>
            {" "}
            <strong>Circular Level:</strong> It is mounted on the automatic
            level, it ensures that the instrument is at a true level point.
         </Text>
         <Text fontSize="2xl" fontWeight="bold" mt={4}>
            Operation:
         </Text>
         <Text fontSize="2xl" fontWeight="bold" mt={4}>
            Mounting an Automatic Level:
         </Text>
         <Text>
            Firstly, the automatic level needs to be mounted on the tripod. For
            that purpose, the instrument is placed on the head of the tripod
            carefully such that the nuts under the instrument perfectly align
            with the bolts present on the head of the tripod, and then the bolts
            are tightened with the nuts, completing the mounting process.
         </Text>
         <Text fontSize="2xl" fontWeight="bold" mt={4}>
            Levelling an Automatic Level:
         </Text>
         <Text>
            Once the auto level is properly mounted on the tripod it then needs
            to be levelled before taking measurements as unleveled instrument
            gives blunderous readings. In order to level the automatic level a
            very simple but efficient enough instrument called “Circular Level”
            is used. Circular Level is actually a very basic and cost-effective
            levelling tool, it contains a small bubble floating inside a small
            circular glass which indicates the state of levelling of a
            horizontal surface or in this case an instrument on the surface. So
            to level, the instrument one needs to take that small bubble in the
            middle of the circular level by adjusting the legs of the Tripod and
            by moving the levelling screws carefully. When the bubble comes in
            the middle of the circle the automatic level will then be considered
            as levelled and ready to take measurements.
         </Text>
         <ContactPage/>
      </Box>
   );
};

export default AutoLevel;
