import React from "react";
import {
   Box,
   Container,
   Heading,
   Text,
   Button,
   Image,
   useMediaQuery,
   Flex,
} from "@chakra-ui/react";

function InspiringSection({ imageUrl, sentence }) {
   const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)");

   return (
      <Box
        
         bgSize="cover"
         bgImage={`url(${imageUrl})`}
         bgPosition="center"
         minHeight="400px" // Adjust the height as needed
         display="flex"
         justifyContent="center"
         alignItems="center"
         textAlign="center"
         color="white"
      >
         <Container maxW="container.xl">
            <Box
               bg="rgba(0, 0, 0, 0.5)" // Semi-transparent background color
               p={8}
               borderRadius="5px"
               boxShadow="lg"
            >
               <Heading
                  as="h2"
                  size={isLargerThanMobile ? "2xl" : "xl"}
                  fontSize={isLargerThanMobile ? "3xl" : "2xl"}
                  mb={isLargerThanMobile ? 4 : 2}
                  color="cyan.500"
               >
                  {isLargerThanMobile
                     ? "Unlock Your Potential"
                     : "Inspire & Connect"}
               </Heading>
               <Text
                  textAlign="center"
                  fontSize={isLargerThanMobile ? "lg" : "md"}
                  mb={isLargerThanMobile ? 6 : 4}
                  color="gray.200"
               >
                  {isLargerThanMobile
                     ? `${sentence}`
                     : "Reach out to us for all your construction and surveying needs."}
               </Text>
               <Flex justify="center">
                  <Button
                     backgroundColor="cyan.500" // Button background color
                     color="white" // Button text color
                     fontSize="lg"
                     py="3"
                     px="6"
                     borderRadius="5px"
                     _hover={{ backgroundColor: "cyan.600" }} // Hover color
                  >
                     Contact Us
                  </Button>
               </Flex>
            </Box>
         </Container>
      </Box>
   );
}

export default InspiringSection;
