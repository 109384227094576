import React from "react";
import SurveysTypes from "../components/surveys/typesSurveys";
import TeamMembers from "../components/Team/TeamMembers";
import ContactPage from "../components/Contact";
import InspiringSection from "../components/Inspiring";


const Home = () => {
   return (
      <>
        
         <img
            src="https://www.takeoffpros.com/wp-content/uploads/2020/02/02-construction-surveying.png.webp"
            alt=""
            width={"100%"}
         />

         {/* <ConstructionAndFacility/> */}
         {/* <ServicesSection/> */}
         {/* <Carousel/> */}
         {/* <ResponsiveCarousel/> */}
         {/* <FixedTabs/> */}
         <SurveysTypes />
         <img
            src="https://www.hbpateldiploma.org/slider/data1/images/cd2.png"
            alt=""
         />
         <TeamMembers />
         <InspiringSection
            imageUrl={
               "https://www.onlineschoolsreport.com/wp-content/uploads/2020/10/OSR-FAQ-CivilEngineering-WhatIsSurveying-Header.jpg"
            }
            sentence={
               "Construction is a matter of optimism. It is facing the future with confidence."
            }
         />

         <ContactPage />

        
      </>
   );
};

export default Home;
