import React from "react";
import {
  Box,
  Heading,
  Text,
  Flex,
  Icon,
  Link,
  SimpleGrid,
  Input,
  Textarea,
  Button,
} from "@chakra-ui/react";
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";

const ContactPage = () => {
  return (
    <Box p={8} borderRadius="lg" bg="white" boxShadow="lg">
      <Heading as="h1" size="xl" mb={6} color="teal.500">
        Get in Touch
      </Heading>
      <Text fontSize="lg" mb={6} color="gray.600">
        We are here to assist you. Feel free to reach out to us with any questions, comments, or inquiries.
      </Text>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} mb={8}>
        {/* Contact Information */}
        
          <Box width={{ base: "100%", md: "50%" }}>
            <iframe
              title="Interactive Map of Our Office Locations"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d925.9185213493391!2d75.61197570177268!3d21.83151990000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd885d10bec85c3%3A0xdc7de67c41e1297!2sRJJ6%2BMX4%2C%20Sitavallabh%20Market%20Rd%2C%20Vallabh%20Nagar%20Colony%2C%20Jawahar%20Nagar%2C%20Khargone%2C%20Madhya%20Pradesh%20451001!5e0!3m2!1sen!2sin!4v1695014013190!5m2!1sen!2sin"
              width="100%"
              height="450"
              // style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </Box>
          <Flex alignItems="center">
          <Box>
          <Icon as={FaMapMarkerAlt} fontSize="2xl" mr={4} color="teal.500" />
            <Heading as="h2" size="lg" color="gray.700">
              Location
            </Heading>
            <Text color="gray.600">Sita Vallabh Market</Text>
            <Text color="gray.600">Khargone, Madhya Pradesh 451001 </Text>
          </Box>
        </Flex>

        <Flex alignItems="center">
          <Icon as={FaPhone} fontSize="2xl" mr={4} color="teal.500" />
          <Box>
            <Heading as="h2" size="lg" color="gray.700">
              Phone
            </Heading>
            <Link href="tel:+919165372732" color="gray.600">
              +91 91653-72732
            </Link>
          </Box>
        </Flex>

        <Flex alignItems="center">
          <Icon as={FaEnvelope} fontSize="2xl" mr={4} color="teal.500" />
          <Box>
            <Heading as="h2" size="lg" color="gray.700">
              Email
            </Heading>
            <Link href="ravindra.kumar.badole@gmail.com" color="gray.600">
             ravindra.kumar.badole@gmail.com
            </Link>
          </Box>
        </Flex>
      </SimpleGrid>

      {/* Contact Form */}
      <Heading as="h2" size="lg" mb={4} color="teal.500">
        Send us a Message
      </Heading>
      <form>
        <Flex flexDir={{ base: "column", md: "row" }} mb={4}>
          <Box flex={1} mb={{ base: 4, md: 0 }} mr={{ md: 4 }}>
            <Input
              type="text"
              placeholder="Your Name"
              bg="gray.100"
              border="none"
              p={3}
              _focus={{ outline: "none" }}
              borderRadius="md"
              transition="border-color 0.3s"
              _hover={{ borderColor: "teal.400" }}
            />
          </Box>
          <Box flex={1}>
            <Input
              type="email"
              placeholder="Your Email"
              bg="gray.100"
              border="none"
              p={3}
              _focus={{ outline: "none" }}
              borderRadius="md"
              transition="border-color 0.3s"
              _hover={{ borderColor: "teal.400" }}
            />
          </Box>
        </Flex>
        <Textarea
          rows="4"
          placeholder="Your Message"
          bg="gray.100"
          border="none"
          p={3}
          _focus={{ outline: "none" }}
          mb={4}
          borderRadius="md"
          width={"80%"}
          transition="border-color 0.3s"
          _hover={{ borderColor: "teal.400" }}
        />
        <br />
       
        <Button
          type="submit"
          size={"100px"}
          p={2}
          bgGradient="linear(to-r, teal.400, teal.600)"
          color="white"
          _hover={{ bgGradient: "linear(to-r, teal.500, teal.700)" }}
          borderRadius="lg"
        >
          Send Message
        </Button>
      </form>
    </Box>
  );
};

export default ContactPage;

