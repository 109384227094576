import React from "react";
import InspiringSection from "../components/Inspiring";
import one from "../components/images/gdps.jpg";
import ResponsiveCarousel from "../components/ResposiveCar";
import ContactPage from "../components/Contact";
import ConstructionAndFacility from "../components/ConstructionAndFacility";
import { Text, Heading, UnorderedList, ListItem } from "@chakra-ui/react";
const Land = () => {
   return (
      <div>
         <div>
            <InspiringSection
               imageUrl={one}
               sentence={
                  "Professional land surveyors take on a lot of responsibility, and should be compensated appropriately."
               }
            />
         </div>
         {/* <ConstructionAndFacility/> */}
         <Heading as="h1" fontSize="2xl" mb={4}>
            What Is a Land Survey and What are the Types of Surveys?
         </Heading>

         <Text>
            There are several reasons why you might want to conduct a land
            survey. Are land surveys the only type of option available when it
            comes to solving disputes or planning existing or new developments?
            In this article, we’ll be discussing what is a land survey and what
            other types of surveys are available when it comes to real estate
            and clearing up confusion in general.{" "}
         </Text>
         <Heading as="h1" fontSize="2xl" mb={4}>
            What is a Land Survey?
         </Heading>
         <Text mb={"4px"}>
            So what is a land survey? In its most basic terms, it’s a graphic
            layout of a property. It maps the legal boundaries and other
            features of a property that might be needed when it comes to solving
            any issues that might be brought up during real estate transactions.
            They can be valuable tools to use to clarify any disputes. It’s a
            way of measuring structures and locating existing features of a
            property.
         </Text>
         <Heading as="h1" fontSize="2xl" mb={4}>
            How are Land Surveys Made?
         </Heading>
         <Text mb={4}>
            When it comes to land surveys, they are made from various tools,
            depending on which one is appropriate. For example, an altimeter
            would be able to measure a property’s elevation, whereas other tools
            like GPS that can also prove to be useful when completing a survey.
         </Text>
         <Text mb={4}>
            The most common tools used during land surveys are the theodolite,
            which can help measure horizontal and vertical angles between
            points. Total stations are also common and ones you’ve liked seen in
            passing when on your way to work.
         </Text>
         <Heading as="h1" fontSize="2xl" mb={4}>
            Why Get a Land Survey?
         </Heading>
         <img
            src="https://millmanland.com/wp-content/uploads/2020/05/house-with-frontyard.png"
            alt=""
         />
         <Text mb={4} mt={4}>
            Land surveys aren’t always required in a real estate transaction,
            but they can be useful for solving any issues or ongoing disputes
            that might occur between the buyer and seller of the property.{" "}
         </Text>
         <Heading as="h1" fontSize="2xl" mb={4}>
            <b>
               Here are some reasons why you might want to get a land survey:{" "}
            </b>
         </Heading>
         <Heading as="h1" fontSize="2xl" mb={4}>
            Resolve Boundary Issues :
         </Heading>
         <Text mb={4}>
            Boundary issues can often be a common reason as to why land surveys
            are conducted when there are disagreements over where one person’s
            property begins and ends. It’s important because every square meter
            can count when it comes to making money on a property as a seller.{" "}
         </Text>
         <Heading as="h1" fontSize="2xl" mb={4}>
            Pinpoint Plot Size & Price :{" "}
         </Heading>
         <Text mb={4}>
            With land surveys, being able to know how big a plot size is can
            help dictate the price of the property. As a result, it can be a
            great way to negotiate when it comes to buyers who want to try and
            bargain if the plot is smaller than what’s being listed. It can be a
            great advantage for individuals who are planning to buy a property.
         </Text>
         <Heading as="h1" fontSize="2xl" mb={4}>
            Build a New Home :
         </Heading>
         <Text mb={4}>
            If you’re in the position to build your own home, then it’s a good
            idea to conduct a land survey. It can help find places where you can
            build the main structure or perhaps establish the location of the
            drainage system.
         </Text>
         <Text mb={4}>
            <i>
               Are you building a new home? Contact Bhagawati Land Survey today!
            </i>
         </Text>
         <Heading as="h1" fontSize="2xl" mb={4}>
            What to Look For in a Land Survey?
         </Heading>
         <Text mb={4}>
            When it comes to getting a land survey, you want it to include the
            property’s boundaries and any improvements that could be made as a
            result. Any setbacks and restrictions that the property has will
            also need to be identified through this survey and whether it’s on a
            floodplain or not. A land survey should also include a written
            description that is included within the property deed because not
            all properties have the visual boundary markers needed.
         </Text>

         <Heading as="h2" fontSize="lg" mb={4}>
            Types of Surveys
         </Heading>
         <UnorderedList m={4}>
            <ListItem>
               <Text m={["5px 4px", "10px 8px"]}>
                  <strong>Boundary Survey:</strong> A boundary survey includes
                  the boundaries of a specific property, such as the physical
                  markers to define where the boundaries are as well as recorded
                  deeds. It often involves fieldwork in the case of adjoining
                  lands to figure out where one ends and begins.
               </Text>
               <Text mb={4}>
                  <strong>Typical cost:</strong> $450 to $600
               </Text>
            </ListItem>
            <ListItem>
               <Text mb={4}>
                  <strong>Construction Survey:</strong> This type of land survey
                  is often used when sorting out structures such as roads,
                  buildings, and other utilities. There are horizontal and
                  vertical grading that takes place to complete this type of
                  survey.
               </Text>
               <Text mb={4}>
                  <strong>Typical cost:</strong> $1,000 to $1,500
               </Text>
            </ListItem>
            <ListItem>
               <Text mb={4}>
                  <strong>Location Survey:</strong> Similar to a boundary
                  survey, as mentioned above, a location survey has a bit more
                  information added in terms of interior improvements. It is
                  usually used to help adhere to the requirements of loan
                  applications and zoning permits.
               </Text>
               <Text mb={4}>
                  <strong>Typical cost:</strong> $200 to $710
               </Text>
            </ListItem>
            <ListItem>
               <Text mb={4}>
                  <strong>Site Planning Survey:</strong> This is a survey that
                  combines the boundary and topographic survey mentioned further
                  down. It’s often used for designing homes, commercial
                  properties, and industrial sites.
               </Text>
               <Text mb={4}>
                  <strong>Typical cost:</strong> Varies significantly
               </Text>
            </ListItem>
            <ListItem>
               <Text mb={4}>
                  <strong>Subdivision Survey:</strong> When dividing lands into
                  smaller lots or estates, this is the type of survey that’s
                  needed. It can often be a survey for streets and drainage
                  systems as well as lots.
               </Text>
               <Text mb={4}>
                  <strong>Typical cost:</strong> $300 to $400 per lot
               </Text>
            </ListItem>
            <ListItem>
               <Text mb={4}>
                  <strong>Topographic Survey:</strong> This is a typical survey
                  that is used to locate features such as utilities, roads,
                  embankments, and ditches. It’s usually shown as
                  three-dimensional points that feature the property, land
                  features and physical boundary in detail.
               </Text>
               <Text mb={4}>
                  <strong>Typical cost:</strong> $400 to $9,500
               </Text>
            </ListItem>
            <ListItem>
               <Text mb={4}>
                  <strong>Mortgage Survey:</strong> Similar to ALTA surveys,
                  mortgage surveys are most common when someone purchases a home
                  or piece of property. This survey verifies property
                  structures, title owners, and building and zoning codes prior
                  to buying.
               </Text>
               <Text mb={4}>
                  <strong>Typical cost:</strong> $350 to $600
               </Text>
            </ListItem>
            <ListItem>
               <Text mb={4}>
                  <strong>ALTA Survey:</strong> ALTA surveys (or American Land
                  Title Association surveys) provide title companies and lenders
                  required to issue an ALTA insurance policy. We commonly
                  perform these land surveys for those investing in property or
                  buying a new home.
               </Text>
               <Text mb={4}>
                  <strong>Typical cost:</strong> $1,500 to $4,500
               </Text>
            </ListItem>
         </UnorderedList>
         <Heading as="h1" fontSize="2xl" mb={4}>
            Can You Survey Your Own Land?
         </Heading>
         <Text mb={4}>
            Yes, but if you survey your own property, you can only use the
            results for your personal records and not for legal purposes. So
            hiring professional land surveyors is essential if you want or need
            to submit the documentation as a legal property record.
         </Text>
         <Text mb={4}>
            If you purchased a property without a survey, you can usually find
            your boundary lines in the property deed. You can also find any past
            land survey records by contacting your local county assessor’s
            office or website.
         </Text>
         <Heading as="h1" fontSize="2xl" mb={4}>
            How Can We Help?
         </Heading>
         <img
            src="https://millmanland.com/wp-content/uploads/2020/05/man-smiling-on-the-phone.png.webp"
            alt=""
         />
         <Text mb={4} mt={3}>
            When it comes to looking for the right land surveyor, it’s vital to
            find the right company, which is where Millman National Land
            Services comes in the picture. With over twenty years of experience
            in the industry, you can guarantee nothing but the best, whether you
            need one individual to survey or multiple.
         </Text>
         <Text mb={4}>
            Whatever the size of the project is, a successful real estate
            transaction is not only a dream but a reality, which is why this
            company exceeds national standards. With innovative management and
            state-of-the-art technology, you can expect efficiency, consistency,
            and accuracy for all their services.
         </Text>
         <Heading as="h2" fontSize="2xl" mb={4}>
            Do You Need a Land Survey?
         </Heading>
         <Text mb={4}>
            Land surveys are an essential part of a real estate transaction
            where certain disputes or inquiries require a solution. Whether it’s
            a commercial building or a new home, it can be handy to have one of
            these surveys conducted to help solve problems that could delay the
            process or hinder any opportunities for a successful sale on a
            property. If you’re looking at getting a land survey, assess what is
            needed and required so that you can pick the right survey for a
            specific property or project.
         </Text>

         <ContactPage />
      </div>
   );
};

export default Land;
